import { sentry } from '@chilipiper/config'
import {
  Integrations,
  init as initSentry,
  BrowserOptions,
  setTag,
  reactRouterV6Instrumentation,
  captureMessage,
  Replay,
} from '@sentry/react'
// Use it if you need more data in sentry logs..it creates more events and it's expensive
// import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import { BrowserTracing } from '@sentry/tracing'

const init = (
  dsn: string,
  options: BrowserOptions = {},
  routingInstrumentation?: ReturnType<typeof reactRouterV6Instrumentation>
) => {
  initSentry({
    dsn,
    debug: process.env.NODE_ENV === 'development',
    release: sentry.release,
    integrations: [
      new BrowserTracing(
        routingInstrumentation
          ? {
              routingInstrumentation,
            }
          : undefined
      ),
      // new CaptureConsoleIntegration({
      //   levels: ['log', 'error', 'warn'],
      // }),
      new Replay(),
      new Integrations.TryCatch(),
      new Integrations.FunctionToString(),
      new Integrations.Breadcrumbs({
        dom: {
          serializeAttribute: ['data-test-id', 'class'],
        },
      }),
    ],
    tracesSampleRate: 1,
    environment: sentry.env,
    autoSessionTracking: true,
    sampleRate: 1,
    denyUrls: [
      // Rollout server error
      /rollout\.io/,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    ignoreErrors: [
      'TypeError: Cancelled', //https://forum.sentry.io/t/typeerror-cancelled-how-do-i-find-out-which-request-it-was/10786
      'TypeError: Failed to fetch',
      'Non-Error exception captured with keys',
      'UnhandledRejection: Non-Error promise rejection captured', //https://docs.google.com/document/d/172Sc618sdMUr3Nxa1iEr6z8vqQatvy2iA06N3m-v9J0
      'TypeError: NetworkError when attempting to fetch resource.',
      'ResizeObserver loop limit exceeded', // https://stackoverflow.com/a/50387233/2652124
      ...(options.ignoreErrors || []),
    ],
    ...options,
  })

  setTag('environment', sentry.env)
}

enum SeverityLevels {
  Debug = 'debug',
  Error = 'error',
  Fatal = 'fatal',
  Info = 'info',
  Log = 'log',
  Warning = 'warning',
}

export { captureMessage, init, SeverityLevels }
